module.exports = [{
      plugin: require('/Users/danielmaistern/repositories/novotalk_website/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["IBM Plex Sans:300,400,600,700","Roboto"]}},
    },{
      plugin: require('/Users/danielmaistern/repositories/novotalk_website/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-104640365-7"},
    },{
      plugin: require('/Users/danielmaistern/repositories/novotalk_website/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
