// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-layouts-programs-layout-index-js": () => import("/Users/danielmaistern/repositories/novotalk_website/src/layouts/programsLayout/index.js" /* webpackChunkName: "component---src-layouts-programs-layout-index-js" */),
  "component---src-pages-404-js": () => import("/Users/danielmaistern/repositories/novotalk_website/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("/Users/danielmaistern/repositories/novotalk_website/src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-contact-index-js": () => import("/Users/danielmaistern/repositories/novotalk_website/src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-faq-index-js": () => import("/Users/danielmaistern/repositories/novotalk_website/src/pages/faq/index.js" /* webpackChunkName: "component---src-pages-faq-index-js" */),
  "component---src-pages-index-js": () => import("/Users/danielmaistern/repositories/novotalk_website/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-methodology-index-js": () => import("/Users/danielmaistern/repositories/novotalk_website/src/pages/methodology/index.js" /* webpackChunkName: "component---src-pages-methodology-index-js" */),
  "component---src-pages-partners-index-js": () => import("/Users/danielmaistern/repositories/novotalk_website/src/pages/partners/index.js" /* webpackChunkName: "component---src-pages-partners-index-js" */),
  "component---src-pages-pricing-index-js": () => import("/Users/danielmaistern/repositories/novotalk_website/src/pages/pricing/index.js" /* webpackChunkName: "component---src-pages-pricing-index-js" */),
  "component---src-pages-sign-up-index-js": () => import("/Users/danielmaistern/repositories/novotalk_website/src/pages/sign-up/index.js" /* webpackChunkName: "component---src-pages-sign-up-index-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/danielmaistern/repositories/novotalk_website/.cache/data.json")

